import { FireFilled, SparkleFilled, StarFilled, TimerFilled } from '@zealy/icons';

export const COMMUNITY_COLLECTIONS = [
  {
    value: 'new',
    seo: 'new-web3-communities',
    inlineInDesktop: true,
    icon: <SparkleFilled />,
  },
  {
    value: 'featured',
    seo: 'best-web3-communities',
    inlineInDesktop: true,
    icon: <StarFilled />,
    trackClick: true,
    web2: true,
  },
  {
    value: 'trend',
    seo: 'top-web3-communities',
    inlineInDesktop: true,
    icon: <FireFilled />,
  },
  {
    value: 'upcoming',
    seo: 'upcoming-web3-communities',
    inlineInDesktop: true,
    icon: <TimerFilled />,
  },
] as const;

export const COMMUNITY_CATEGORIES = [
  {
    value: 'all',
    seo: '',
  },
  {
    value: 'startup',
    seo: 'best-web3-startup',
  },
  {
    value: 'gaming',
    seo: 'web3-gaming',
  },
  {
    value: 'infrastructure',
    seo: 'web3-infrastructure',
  },
  {
    value: 'defi',
    seo: 'defi-communities',
  },
  {
    value: 'music',
    seo: 'web3-music',
  },
  {
    value: 'metaverse',
    seo: 'metaverse-communities',
  },
  {
    value: 'collectibles',
    seo: 'best-crypto-collectibles',
  },
  {
    value: 'education',
    seo: 'learn-web3',
  },
  {
    value: 'art',
    seo: 'web3-art',
  },
  {
    value: 'nft',
    seo: 'best-nft-communities',
  },
  {
    value: 'dao',
    seo: 'best-dao-communities',
  },
  {
    value: 'meme',
    seo: 'best-meme-communities',
  },
  {
    value: 'ai-agent',
    seo: 'best-ai-agent-communities',
  },
] as const;

export type CommunityCategory = (typeof COMMUNITY_CATEGORIES)[number]['value'];
export type CommunityCategorySeo = (typeof COMMUNITY_CATEGORIES)[number]['seo'];
export type HomeCommunityCategory = Extract<CommunityCategory, 'nft' | 'art' | 'dao'>;

export type CommunityCollection = (typeof COMMUNITY_COLLECTIONS)[number]['value'];
export type CommunityCollectionSeo = (typeof COMMUNITY_COLLECTIONS)[number]['seo'];

export const COMMUNITY_CATEGORY_IDS = [
  ...COMMUNITY_CATEGORIES.map(c => [c.seo, c.value] as const),
  ...COMMUNITY_COLLECTIONS.map(c => [c.seo, c.value] as const),
].reduce((acc, item) => {
  const [seo, value] = item;
  acc[seo] = value;
  return acc;
}, {} as CommunityCategoryMapper);

export const HOME_CATEGORIES = COMMUNITY_COLLECTIONS.map(c => c.value);
export type CategoryType = CommunityCategory | CommunityCollection;

export type ExploreSeo = CommunityCategorySeo | CommunityCollectionSeo;
export type ExploreType = CommunityCategory | CommunityCollection;

export type CommunityCategoryMapper = {
  // eslint-disable-next-line no-unused-vars
  [k in ExploreSeo]: ExploreType;
};

export type CollectionUrl = `/explore/${ExploreSeo}`;
